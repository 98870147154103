import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { connect } from "react-redux";
import { signOut } from "redux/actions";
import { HashLink as HashLink } from 'react-router-hash-link';

// import IntlMessage from "components/util-components/IntlMessage";

const Header = ({ user, token, signOut }) => {
  const [isOpen, setOpen] = useState(false);
  const isSp = useMediaQuery({ query: '(max-width: 1050px)' });

  const logOut = () => {
    signOut();
    window.location.href = "/";
  };

  return (
    <header className="l-header">
      <div className="l-header__items pc">
        <h1>
          <Link to="/" className="l-header__logo --top">Reao</Link>
        </h1>

        <nav className="l-header__nav-items c-icon__from-left">
          <ul>
            <li><HashLink to="#id-about">Reaoの特徴</HashLink></li>
            <li><HashLink to="#id-point">コレクションとしてのアートの魅力</HashLink></li>
            <li className="l-header__contact"><Link className="contact" to="/contact-us"><span>お問い合わせ</span><small>CONTACT</small></Link></li>
            <li className="l-header__contact --login"><Link className="contact" to="/login"><span>ログイン</span><small>LOGIN</small></Link></li>
            <li className="l-header__contact --register"><Link className="contact" to="/register"><span>会員登録</span><small>REGISTER</small></Link></li>
          </ul>
        </nav>

        <ul className="l-header__sp-mails">
          <li className="l-header__contact --login">
            <Link className="contact" to="/login">
              <div className="l-heading__login-pd">ログイン<br />LOGIN</div>
            </Link>
          </li>
          <li className="l-header__contact --register">
            <Link className="contact" to="/register">
              <span>会員登録</span><br /><small>REGISTER</small>
            </Link>
          </li>
        </ul>

        <div className="l-header__items2">
          <button className="drawer-icon js-drawer for-drawer" type="button" onClick={() => setOpen(!isOpen)}>
            <span id="js-color2" className="drawer-bars">
              <span className="drawer-bar"></span>
              <span className="drawer-bar"></span>
              <span className="drawer-bar"></span>
            </span>
          </button>
        </div>

        {isOpen && (
          <div className="drawer-content-cover for-drawer c-modal__scroll-bar">
            <div className="l-header__hamburger-menu">
              <ul>
                <li><HashLink to="/#id-top">TOP</HashLink></li>
                <li><HashLink to="/#id-about">Reaoの特徴</HashLink></li>
                <li><HashLink to="/#id-point">コレクションとしてのアートの魅力</HashLink></li>
                <li><Link className="l-header__mail-icon" to="/contact-us">お問い合わせ</Link></li>
              </ul>
            </div>

            <div className="l-header__drawer-btns">
              <Link to="/login">ログイン</Link>
              <Link className="--black" to="/register">会員登録</Link>
            </div>

            <div className="l-header__drawer-foot-wrapper">
              <div className="l-header__drawer-foot">
                <ul className="l-header__foot-flex">
                  <li><Link to="/company">会社概要</Link></li>
                  <li><Link to="/sct-law">特商法</Link></li>
                  <li><Link to="/terms">利用規約</Link></li>
                  <li><Link to="/privacy-policy">プライバシーポリシー</Link></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

const mapStateToProps = ({ auth, appStore }) => {
  const { token } = auth;
  const { user } = appStore;
  return { token, user };
};

export default connect(mapStateToProps, { signOut })(Header);
