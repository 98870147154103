import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="l-footer">
      <nav className="l-foote__logo-right c-inner__flex c-icon__from-left">
        <h1>
          <Link to="/#id-top" className="l-header__logo --top">
            Reao
          </Link>
        </h1>
        <ul className="c-inner__flex">
          <li><Link to="/company">会社概要</Link></li>
          <li><Link to="/sct-law">特商法</Link></li>
          <li><Link to="/terms">利用規約</Link></li>
          <li><Link to="/privacy-policy">プライバシーポリシー</Link></li>
        </ul>
        <p className="pc">
          <small className="l-footer__copyright l-footer__pc-copy">
            Copyright&nbsp;&copy;&nbsp;2024 Reao All rights reserved.
          </small>
        </p>
      </nav>
      <p className="sp">
        <small className="l-footer__copyright">
          Copyright&nbsp;&copy;&nbsp;2024 Reao All rights reserved.
        </small>
      </p>
    </footer>
  );
};

export default Footer;
